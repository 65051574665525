<template>
  <v-container fluid id="container" class="d-flex align-center justify-center">
    <v-row class="d-flex align-center">
      <v-col offset="1" offset-md="2" cols="10" md="8">
        <v-row class="text-h4 font-weight-bold mb-8" justify="center">
          {{ $t("signUpHeader") }}
        </v-row>
        <v-row class="d-flex flex-column">
          <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
            {{ $t("passwordLabel") }}
          </v-label>
          <v-text-field
            filled
            :placeholder="$t('password')"
            :type="visiblePassword ? 'text' : 'password'"
            :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            @click:append="visiblePassword = !visiblePassword"
          />
        </v-row>
        <v-row class="my-8 text-subtitle-1 font-weight-medium" align="center">
          <v-checkbox class="font-weight-medium"></v-checkbox>
          {{ $t("rememberMe") }}
        </v-row>
        <v-row class="my-8">
          <v-btn block color="primary" to="/twoFactor" class="text-capitalize">
            {{ $t("createMyAccount") }}
          </v-btn>
        </v-row>
        <v-row class="text-subtitle-1" justify="center">
          {{ $t("alreadyHaveAccount") }}
          <router-link
            to="/login"
            class="text-decoration-none font-weight-bold fs-14 ml-6"
            id="sign-up-link"
            >{{ $t("logintoyouraccount") }}</router-link
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeAuthService from "@/services/api/auth";
import { createNamespacedHelpers } from "vuex";
import { handleDuplicateMixin } from "@/mixins/handleDuplicate.js";
const { mapMutations, mapActions } = createNamespacedHelpers("user");

export default {
  data() {
    return {
      user: {
        handle: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      visiblePassword: false,
    };
  },
  computed: {
    emailValidation() {
      return [
        (v) => !!v || this.$t("emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("validEmail"),
      ];
    },
    nameValidation() {
      return [
        (v) => !!v || this.$t("inputRequired"),
        (v) => (v && v.length >= 2) || this.$t("min2Chars"),
      ];
    },
    passwordValidation() {
      return [
        (v) => !!v || this.$t("passwordRequired"),
        (v) => (v && v.length >= 6) || this.$t("min6Chars"),
      ];
    },
    usernameValidation() {
      return [
        (v) => !!v || this.$t("requiredField"),
        (v) =>
          /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(v) ||
          this.$t("invalidUsername"),
      ];
    },
  },
  mixins: [handleDuplicateMixin],
  methods: {
    ...mapMutations(["setUser"]),
    ...mapActions({
      initSettings: "initSettings",
    }),
    saveUser() {
      const authService = new makeAuthService(this.$api);
      authService
        .register(this.user)
        .then(async (response) => {
          // CTODO - pull this function out as it is the same for login
          const user = response.data.user;
          this.setUser(user);
          localStorage.setItem("user", JSON.stringify(user));
          this.initSettings();
          await this.$swal({
            title: this.$t("signUpsuccess"),
            icon: "success",
            showConfirmButton: false,
            position: "top-end",
            timer: 2000,
            toast: true,
          });
          if (
            this.$router.history._startLocation !== "/" &&
            this.$router.history._startLocation !== "/signup" &&
            this.$router.history._startLocation !== "/login" &&
            this.$router.history._startLocation !== this.$router.currentRoute.path
          ) {
            this.$router.push(this.$router.history._startLocation).catch((e) => {
              console.log(e);
            });
          } else {
            this.$router.push("/").catch((e) => {
              console.log(e);
            });
          }
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: this.$t("error"),
            text: error,
          });
        });
    },
    created() {},
    resetForm() {
      this.user = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>
